import { FormField, SingleDatePicker } from '@sede-x/shell-ds-react-framework';
import { FieldProps } from 'formik';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import dayjs from 'dayjs';

dayjs.extend(weekday);
dayjs.extend(localeData);

interface CustomInputComponent {
  format?: string;
}

const DatePicker = (props: FieldProps & CustomInputComponent) => {
  const {
    field,
    form: { touched, errors },
    ...rest
  } = props;

  const { format } = rest;

  return (
    <div>
      <FormField size="small" {...field} {...rest}>
        <SingleDatePicker
          elevation
          inputReadOnly
          format={format}
          getPopupContainer={(triggerNode: HTMLElement) =>
            triggerNode.parentElement as HTMLElement
          }
          {...rest}
          invalid={!!(touched[field.name] && errors[field.name])}
          data-testid={`${field.name}-field`}
        />
        {touched[field.name] && errors[field.name] && (
          <div className="text-red-500 text-sm">
            {errors[field.name] as string}
          </div>
        )}
      </FormField>
    </div>
  );
};
export default DatePicker;
