interface EndpointType {
  [key: string]: string;
}

export const exportAllEndpoints: EndpointType = {
  exportAll: 'exportAll',
  SFH: 'exportAll/sfh',
  DuplicateTollDomainRegistration: 'exportAll/duplicatetolldomainregistration',
  telepassELCONReport: 'exportAll/elcon',
  slovakiaTransaction: 'exportall/slovakiatransaction'
};

export const actionButtonEndpoints: EndpointType = {
  germanyTransactionError: 'report/action/germany/transactionerror/deleted',
  germanyContractDeregistered:
    'report/action/germany/contractderegistered/block',
  t4eTransferObuExceptions: 'report/action/t4e/transferobuexceptions/add',
  insertCardChangeRequests:
    '/report/action/czechtollcard/insertcardchangerequests',
  czechTollTemplateDownload: 'report/action/czechtollcard/downloadtemplate',
  slovakiaTemplateDownload:
    'report/action/slovakiacardexpiry/changecards/downloadtemplate',
  slovakiaUploadTemplate:
    'report/action/slovakiacardexpiry/changecards/uploadtemplate',
  slovakiaCardChange: 'report/action/slovakiacardexpiry/changecard',
  czechUnknownContractsAssign: 'report/action/czechunknowncontracts/assign',
  slovakiaUnknownContractsAssign:
    'report/action/slovakiaunknowncontracts/assign',
  slovakiaUnknownContractsCustomer:
    'report/action/slovakiaunknowncontracts/customer'
};

const endpointConstants: EndpointType = {
  cardBlocked: 'card/cardsblocked',
  cardExpired: 'card/cardsexpired',
  contractList: 'contract/contractlist',
  transaction: 'transaction'
};

export const endpoints: EndpointType = {
  blacklist: 'blacklist',
  cardIssuerIncorrect: 'cardIssuerIncorrect',
  cardinuse: 'cardinuse',
  userActive: 'useractive',
  userError: 'user/usererror',
  cardExchange: 'cardexchange',
  lastCardExchange: 'lastCardExchange',
  cardIncorrectAssigned: 'cardincorrectassigned',
  customerRegistration: 'customerregistration',
  customerComments: 'customercomment',
  blockedCustomer: 'blockedCustomer',
  customerContacts: 'customercontact',
  customerStatus: 'customerStatus',
  reports: 'etollmanager',
  interfaceRunError: 'interfacerunerror',
  interfaceRuns: 'interfacerun',
  noVirtualCardActivated: 'novirtualcardactivated',
  duplicatedVehicles: 'duplicatedvehicle',
  vehicleWithCardsInfo: 'vehiclewithcardinformation',
  registeredAccountsOverview: 'registeredaccountsoverview',
  systemJobs: 'systemjob',
  SFH: 'sfh',
  customerCardOrderPending: 'gfn/gfncustomercardorderpending',
  userList: 'user/userlist',
  customerMissingMail: 'customermissingmail',
  gfncustomerCardOrder: 'gfn/customercardorder',
  gfncustomerCardOrderError: 'gfn/customercardordererror',
  gfntransaction: 'gfn/transaction',
  gfnVehicleCardOrderError: 'gfn/vehiclecardordererror',
  gfnVehicleCardOrder: 'gfn/vehiclecardorder',
  gfnExpiredCardReports: 'gfn/cardsexpired',
  gfnVirtualCardOrder: 'gfn/virtualcardorder',
  gfnCardExchangeHistory: 'gfn/cardexchangehistory',
  gfnOpenTransaction: 'gfn/opentransaction',
  gfnTransactionError: 'gfn/transactionerror',
  gfnMissingCardVehicle: 'gfn/missingcardvehicle',
  gfnCardBlocked: 'gfn/cardblocked',
  belgiumCardBlocked: endpointConstants.cardBlocked,
  belgiumCardExpired: endpointConstants.cardExpired,
  belgiumCardSyncErrors: 'card/cardsynchronizationerrors',
  belgiumTransaction: endpointConstants.transaction,
  belgiumContracts: 'contract',
  belgiumCustomerListReport: 'customer/customerlist',
  belgiumObusOnBlackList: 'obu/obusonblacklist',
  belgiumUnknownContracts: 'contract/unknowncontract',
  belgiumUnknownCountries: 'country/unknowncountries',
  telepassErrorReportOBU: 'error/obu',
  errorReportContract: 'error/contract',
  telepassCardBlocked: endpointConstants.cardBlocked,
  telepassCardExpired: endpointConstants.cardExpired,
  telepassOBUListReport: 'obu/obulist',
  telepassObuServiceListReport: 'obu/obuservicelist',
  telepassELCONReport: 'transaction/elcon',
  telepassOBUMonthlyRentalFees: 'obu/monthlyrentalfees',
  telepassOBUMonitoringReport: 'obu/monitoring',
  telepassContractList: endpointConstants.contractList,
  telepassOrderReport: 'SFH/telepassorder',
  ukLevyCardBlocked: endpointConstants.cardBlocked,
  ukLevyTransaction: endpointConstants.transaction,
  activeEvignettes: 'evignettes/activeevignettes',
  evignettesOpenOrders: 'order/openorders',
  evignettesOrders: 'order',
  telepassVehicleList: 'vehicle/vehiclelist',
  evignetteCardBlocked: endpointConstants.cardBlocked,
  evignetteCardExpired: endpointConstants.cardExpired,
  ukLevyCardExpired: endpointConstants.cardExpired,
  evignetteError: 'error',
  evignetteRegisteredCustomers: 'customer/eurovignetteregisteredcustomers',
  germanyDeregisteredbyCustomer: 'customer/deregisteredbycustomer',
  czechTollRegAccounts: 'customer/registeredaccounts',
  czechTollRegistrationRequests: 'customer/registerationrequest',
  czechTollCardChange: 'card/cardchangerequest',
  czechTollCardBlocked: 'card/cardsblocked',
  czechTollCardExpired: endpointConstants.cardExpired,
  czechTollExpiredCards: 'action/czechtollcard/expiredcards',
  czechTollUnknownContracts: 'action/czechunknowncontracts',
  germanyInvoice: 'transaction/transactioninvoice',
  germanyTransNotificationDaily: 'transaction/notification/daily',
  germanyNotificationMonthly: 'transaction/notification/monthly',
  germanyInboundOutbound: 'transaction/inboundoutbound',
  germanyContractList: endpointConstants.contractList,
  germanyContractStatus: 'contract/contractstatus',
  germanyLimitConfirmations: 'customer/limitconfirmation',
  germanyLimitConfirmationsErrors: 'error/limitconfirmation',
  germanyLimitRequests: 'customer/limitrequest',
  germanyTransactionError: 'error/transaction',
  germanyTransactionESI: 'transaction/esi',
  germanyTransactionGFN: 'transaction/gfn',
  germanytransNotificationFortnight: 'transaction/notification/fortnight',
  germanyTransReconciliation: 'transaction/reconciliation',
  germanyUninvoice: 'transaction/uninvoice',
  germanyPanIDMismatch: 'customer/panidmismatch',
  germanyUsingBlockedCard: 'card/usingblockedcard',
  germanyCardExpired: endpointConstants.cardExpired,
  germanyCardBlocked: endpointConstants.cardBlocked,
  slovakiaBlacklist: 'customer/blacklist',
  slovakiaContractList: endpointConstants.contractList,
  slovakiaCardBlocked: endpointConstants.cardBlocked,
  slovakiaCardExpired: endpointConstants.cardExpired,
  slovakiaExpiredCards: 'action/slovakiacardexpiry/getexpirecards',
  slovakiaCardChange: 'card/cardchange',
  slovakiaUnknownContracts: 'contract/unknowncontract',
  slovakiaActionsUnknownContracts: 'contract/slovakiaunknowncontract',
  slovakiaCardExpiryByFCE: 'card/cardexpirybyfce',
  slovakiaRejectedOrders: 'order/rejected',
  slovakiaErrors: 'error/errors',
  slovakiaRegistration: 'customer/registration',
  slovakiaUsingBlockedCard: 'card/usingblockedcard',
  slovakiaTransaction: 'transaction/slovakia',
  fdeCardBlocked: endpointConstants.cardBlocked,
  fdeCardExpired: endpointConstants.cardExpired,
  fdeCCLReport: 'customer/ccl',
  fdeRegistrationReport: 'customer/registration',
  fdeTransactionReport: endpointConstants.transaction,
  fdeVirtualCardOrder: 'card/virtualcardorder',
  t4eCardExpired: endpointConstants.cardExpired,
  t4eCardBlocked: endpointConstants.cardBlocked,
  t4eObuMonthlyRentalFees: 'obu/monthlyrentalfees',
  t4eCardPayerAssociationCodes: 'customer/cardpayerassociationcode',
  autopayCardBlocked: endpointConstants.cardBlocked,
  autopayCardExpired: endpointConstants.cardExpired,
  autoPayRegisteredVehiclesReport: 'vehicle/registration',
  autoPayVehicleRequestReport: 'vehicle/request',
  swizCardExpired: endpointConstants.cardExpired,
  swizCardBlocked: endpointConstants.cardBlocked,
  swizTransaction: endpointConstants.transaction,
  UnprocessedDeviceOrders: 'SFH/unprocesseddeviceorders',
  DuplicateTollDomainRegistration: 'SFH/duplicatetolldomainregistration',
  axxesOrder: 'SFH/axxesorder',
  germanyContractDeregistered: 'action/germany/contractderegistered',
  t4eTransferObuExceptions: 'action/t4e/transferobuexceptions',
  ShellConsortium: 'Consortium/membersandvehicles',
  evignetteRemove: 'action/evignette/customers'
};
