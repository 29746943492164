import axios, { AxiosRequestConfig } from 'axios';

export const baseUrl = process.env.REACT_APP_CUSTOMER_API_BASE;

const axiosConfig: AxiosRequestConfig = {
  baseURL: baseUrl
};

export const UN_AUTH_STATUS = 401;

export function createAxiosResponseInterceptor() {
  customerInstance.interceptors.response.use(
    (response) => Promise.resolve(response),
    async (error) => {
      if (error.response.status !== UN_AUTH_STATUS) {
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );

  customerInstance.interceptors.request.use(
    (config) => {
      const token = JSON.parse(localStorage.getItem('token') ?? '""') || null;

      const auth = token ? `Bearer ${token}` : '';
      config.headers.Authorization = auth;
      return config;
    },
    (error) => Promise.reject(error)
  );
}

const createAxiosInstance = () => axios.create(axiosConfig);

const customerInstance = createAxiosInstance();

createAxiosResponseInterceptor();

export { customerInstance };
