export const Label = ({
  text,
  required
}: {
  text: string;
  required?: boolean;
}) => (
  <span className="text-sm text-shellLabel items-center flex lg:whitespace-nowrap">
    <span>
      <b>{text}</b>
      {required && <span className="text-shellRed">*</span>}
    </span>
  </span>
);
