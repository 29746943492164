/* eslint-disable*/
import { useContext, useState } from 'react';
import {
  Menu,
  Icons,
  Pecten,
  Heading,
  Label,
  Button,
  Popover
} from '@sede-x/shell-ds-react-framework';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { StyledNavbar } from './style';
import { ItemRenderInfo } from './types';
import UserDetails from './UserDetails';
import NotificationPopup from './notificationPopup';
import { AuthContext, IAuthContext } from 'auth';
import { useStaticData } from 'hooks/useStaticData';

const {
  Home,
  ChevronSmallRight,
  Mobile,
  File,
  Location,
  ComposeAlt,
  CheckCircle,
  CalendarNote,
  SwapVertical
} = Icons;

const ElementRender = ({ path, children }: ItemRenderInfo) => (
  <Link to={path}>{children}</Link>
);

const Header = () => {
  const location = useLocation();

  const { tokenData } = useContext<IAuthContext>(AuthContext);

  const { data: userRoles } = useStaticData(
    'userroles',
    'Auth/userroles',
    !!tokenData?.mail,
    null,
    { retryOnMount: false }
  );

  const _hideMenu = [
    {
      itemIcon: <Home />,
      key: '',
      label: 'Home',
      path: '/'
    },

    {
      children: [
        {
          itemIcon: <CheckCircle />,
          key: 'approvals',
          label: 'Approvals'
        },
        {
          itemIcon: <CalendarNote />,
          key: 'registrationRequest',
          label: 'Registration Request'
        },
        {
          itemIcon: <SwapVertical />,
          key: 'uploadOrTemplate',
          label: 'Upload/Templates'
        }
      ],
      itemIcon: <ComposeAlt />,
      key: 'action',
      label: 'Actions'
    },
    {
      key: 'gfn',
      itemIcon: <Mobile />,
      label: 'GFN'
    }
  ];
  const [popupVisible, setPopupVisible] = useState(false);

  return (
    <StyledNavbar
      avatarArea={<UserDetails userRoles={userRoles} tokenData={tokenData} />}
      direction="horizontal"
      pectenArea="none"
    >
      <>
        <div className="flex items-center gap-2.5 py-2.5 px-4">
          <Link to="/">
            <Pecten size={36} className="shrink-0" />
          </Link>
          <div className="flex shrink-0 flex-row items-center">
            <Heading level={4} bold className="font-ShellFont">
              eTM
            </Heading>
            <ChevronSmallRight />
            <Label prominence="subtle" size="small" className="font-SFPro">
              <Breadcrumb />
            </Label>
          </div>
        </div>
        <div className="flex justify-center grow">
          <Menu
            direction="ltr"
            disabledOverflow
            emphasis
            itemRender={ElementRender}
            selectedKeys={[location.pathname.slice(1)]}
            indicatorPosition="right"
            items={[
              {
                children: [
                  {
                    itemIcon: <Location />,
                    key: 'providerSpecific',
                    label: 'Provider Specific',
                    path: 'providerSpecific'
                  }
                ],
                itemIcon: <ComposeAlt />,
                key: 'action',
                label: 'Actions'
              },
              {
                itemIcon: <Location />,
                key: 'providers',
                label: 'Providers',
                path: '/providers'
              },
              {
                itemIcon: <File />,
                key: 'reports',
                label: 'Reports',
                path: 'reports'
              }
            ]}
            mode="horizontal"
            size="medium"
            triggerSubMenuAction="click"
          />
        </div>
        <div className="flex items-center z-20">
          <Popover
            // popupAlign={{offset:[0, -10]}}
            popupVisible={popupVisible}
            popup={
              <NotificationPopup
                setpopupVisible={setPopupVisible}
                userRoles={userRoles}
              />
            }
            popupPlacement="bottomRight"
          >
            <Button
              onClick={() => setPopupVisible(true)}
              icon={<Icons.BellSolid />}
              iconOnly
              size="small"
              variant="transparent"
              data-testid="notification-button"
            />
          </Popover>
        </div>
      </>
    </StyledNavbar>
  );
};

export default Header;
