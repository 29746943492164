import { useEffect, useRef, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel
} from '@tanstack/react-table';

import {
  THead,
  TR,
  TH,
  TBody,
  TD,
  Flexbox,
  SorterButton,
  IPaginationProps,
  EmptyState,
  PaginationSelect
} from '@sede-x/shell-ds-react-framework';

import { PAGE_SIZE_OPTIONS } from 'constants/table';
import { CustomTable } from './types';
import {
  SDSTable,
  StyledTableWrapper,
  TableContainer,
  StyledPagination
} from './style';
import ExportTableData from './TableExport';
import ShowHideColumns from './ShowHideColumns';

const DEFAULT_MAX_HEIGHT = 0;
const DEFAULT_HEADER_SIZE = 150;

function Table<TData>({
  data,
  columns,
  exportFileName = 'report',
  stickyColumns,
  maxHeight = DEFAULT_MAX_HEIGHT,
  maxWidth,
  stickyHeader = true,
  paginationData,
  renderRowSubComponent,
  handleClickExportAll,
  exportAllEnabled,
  exportEnabled = true,
  columnSelection = true,
  onSelectedRowsChange,
  enableMultiRowSelection = true,
  resetSelectedRowsOnPageChange = true
}: CustomTable<TData>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    enableMultiRowSelection
  });

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    if (onSelectedRowsChange) {
      onSelectedRowsChange(
        table.getSelectedRowModel().flatRows.map((row) => row.original)
      );
    }
  }, [rowSelection, table, onSelectedRowsChange]);

  const wrapperProps = {
    ...(maxHeight && { maxHeight }),
    ...(maxWidth && { maxWidth }),
    ...(stickyHeader && {
      stickyRows: stickyHeader
    })
  };

  const handlePageChange = (pageNumber: number, newPageSize: number) => {
    if (resetSelectedRowsOnPageChange) {
      setRowSelection({});
    }
    paginationData?.onPaginationChange(pageNumber, newPageSize);
  };

  const paginationProps: IPaginationProps = {
    total: paginationData?.total,
    current: paginationData?.page,
    pageSize: paginationData?.pageSize,
    onChange: handlePageChange,
    showSizeChanger: true,
    selectComponentClass: PaginationSelect,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showTotal: (total) => `Total ${total} items`
  };

  const isEmpty = table.getRowModel().rows.length === 0;
  const title = 'No data found';

  return (
    <>
      <TableContainer className="pt-4 gap-4">
        {columnSelection && <ShowHideColumns<TData> table={table} />}
        <StyledTableWrapper {...wrapperProps}>
          <div>
            <SDSTable size="small">
              <THead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TR
                    size="small"
                    key={headerGroup.id}
                    depth={headerGroup.depth}
                    {...(stickyHeader && {
                      sticky: true,
                      stickyType: 'header'
                    })}
                    style={{
                      zIndex: 1
                    }}
                  >
                    {headerGroup.headers.map((header) => (
                      <TH
                        key={header.id}
                        colSpan={header.colSpan}
                        {...(stickyColumns?.includes(header?.column.id) && {
                          sticky: true,
                          stickyType: 'column',
                          startingPoint: header?.column.getStart()
                        })}
                        style={{
                          width:
                            header.getSize() !== DEFAULT_HEADER_SIZE
                              ? header.getSize()
                              : undefined
                        }}
                      >
                        <Flexbox
                          gap="12px"
                          alignItems="center"
                          className="whitespace-nowrap"
                        >
                          <>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            {header.column.getCanSort() && (
                              <SorterButton
                                sortDirection={
                                  header.column.getIsSorted() || undefined
                                }
                                onClick={header.column.getToggleSortingHandler()}
                                data-testid={`sort-btn-${header.column.id}`}
                              />
                            )}
                          </>
                        </Flexbox>
                      </TH>
                    ))}
                  </TR>
                ))}
              </THead>
              <TBody>
                {isEmpty && (
                  <TR>
                    <TD
                      colSpan={table.getAllColumns().length}
                      style={{ padding: 'unset' }}
                    >
                      <EmptyState
                        title={title}
                        style={{ padding: '60px', backgroundColor: 'white' }}
                      />
                    </TD>
                  </TR>
                )}
                {table.getRowModel().rows.map((row) => (
                  <>
                    <TR key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <TD
                          key={cell.id}
                          {...(stickyColumns?.includes(cell?.column.id) && {
                            sticky: true,
                            stickyType: 'column',
                            startingPoint: cell?.column.getStart()
                          })}
                          className="whitespace-nowrap"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TD>
                      ))}
                    </TR>
                    {row.getIsExpanded() ? (
                      <TR key={`exp_${row.id}`}>
                        <TD
                          colSpan={table.getVisibleFlatColumns().length}
                          style={{ padding: 'unset' }}
                        >
                          {renderRowSubComponent
                            ? renderRowSubComponent(row)
                            : null}
                        </TD>
                      </TR>
                    ) : null}
                  </>
                ))}
              </TBody>
            </SDSTable>
          </div>
        </StyledTableWrapper>
      </TableContainer>
      <div className="flex flex-col gap-5">
        {paginationData && (
          <StyledPagination
            {...paginationProps}
            size="medium"
            position="center"
          />
        )}
        {exportEnabled && (
          <ExportTableData<TData>
            fileName={exportFileName}
            table={table}
            handleClickExportAll={handleClickExportAll}
            exportAllEnabled={exportAllEnabled}
          />
        )}
      </div>
    </>
  );
}

export default Table;
