import { QueryClient } from '@tanstack/react-query';

// Move to a file
export const queryKeys = {};

export function generateQueryClient(): QueryClient {
  return new QueryClient({});
}

export const queryClient = generateQueryClient();
