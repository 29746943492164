import { Heading, Text } from '@sede-x/shell-ds-react-framework';
import LandingImage from 'assets/images/landing.png';
import styled from 'styled-components';

const Container = styled.div`
  height: calc(100vh - 56px);
`;

const Customer = () => (
  <Container className="flex">
    <div className="flex flex-col w-full p-5">
      <div className="h-full flex flex-col bg-white p-5">
        <div>
          <img src={LandingImage} alt="landing" className="w-full" />
        </div>
        <div className="flex items-center flex-col justify-center grow bg-[#F5F5F5]">
          <Heading level={2} className="font-bold font-">
            <Text prominence="strong" size="large" type="p" bold>
              Welcome to eTM
            </Text>
          </Heading>
          <div className="font-ShellFont text-center">
            Please Browse the options available in top menu
          </div>
        </div>
      </div>
    </div>
  </Container>
);

export default Customer;
