import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../api';

const STALE_TIME = 300000;

interface QueryOptions {
  [key: string]: unknown;
}

export const useStaticData = (
  key: string,
  endpoint: string,
  enabled: boolean,
  params?: unknown,
  options?: QueryOptions
) =>
  useQuery(
    [key],
    async () => {
      const result = await axiosInstance.get(endpoint, { params });

      return result.data;
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME,
      ...options
    }
  );
